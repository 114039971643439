/*  
    ------------------
    Icon sizes
    -----------------
*/
.icon-xs {
    font-size: $font-size-base/1.4;
}
.icon-sm {
    font-size: $font-size-base * 2.1;
}
.icon-md {
    font-size: $font-size-base * 3.2;
}
.icon-lg {
    font-size: $font-size-base * 4.5;
}
.icon-xl {
    font-size: $font-size-base * 6;
}
