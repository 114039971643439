/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $light;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary;
    @include radius(15px);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $secondary;
}
