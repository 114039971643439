﻿@import "components/cards";
@import "components/buttons";
@import "components/breadcrumb";
@import "components/brick";
@import "components/boxes";
@import "components/carsouel";
@import "components/date";
@import "components/figures";
@import "components/list-group";
@import "components/forms";
@import "components/hero";
@import "components/nav";
@import "components/sider";
@import "components/steps";
@import "components/tables";
