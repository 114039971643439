/*  
    --------------
    Owl carousel
    --------------
*/
.owl-carousel {
    &.owl-small {
        .slide {
            height: 400px;
        }
    }

    &.owl-large {
        .slide {
            height: 600px;
        }
    }

    &.owl-fullscreen {
        .slide {
            height: 100vh;
        }
    }

    &.owl-visible {
        .owl-stage-outer {
            overflow: visible;
        }
    }

    &.owl-bottom {
        .owl-stage {
            display: flex;
            align-items: flex-end;
        }
    }

    &.owl-center {
        .owl-stage {
            display: flex;
            align-items: center;
        }
    }

    &.owl-nav-inner {
        .owl-nav {
            button {
                text-align: center;

                &.owl-prev {
                    left: 20px;
                }

                &.owl-next {
                    right: 20px;
                }
            }
        }
    }

    &.owl-nav-clean {
        .owl-nav {
            button {
                text-align: center;

                &.owl-prev,
                &.owl-next {
                    background: transparent;
                }

                &.owl-prev {
                    left: 20px;
                }

                &.owl-next {
                    right: 20px;
                }
            }
        }
    }

    .owl-nav {
        button {
            background-color: transparent;
            outline: none;

            &.owl-prev,
            &.owl-next {
                background: $secondary;
                color: white;
                position: absolute;
                top: 50%;
                width: 40px;
                height: 40px;
                line-height: 1;
                @include transform(translateY(-50%));
                @include radius(50%);
                @include transition(all 0.4s);
            }

            &.disabled {
                opacity: 0;
            }

            &.owl-prev {
                left: -10px;

                @include media-breakpoint-up(md) {
                    left: -20px;
                }
            }

            &.owl-next {
                right: -10px;

                @include media-breakpoint-up(md) {
                    right: -20px;
                }
            }
        }
    }

    .owl-dots {
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        top: 100%;
        @include transform(translateY(5px));

        .owl-dot {
            span {
                width: 10px;
                height: 10px;
                display: block;
                margin: 0 $spacer/2;
                background-color: $gray-300;
                @include radius(50%);
                @include transition(all 0.4s);
            }

            &.active {
                span {
                    background-color: $secondary;
                    @include transform(scale(1.5));
                }
            }
        }
    }

    .slide {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include transition(all 1s);
    }
}

/*  
    --------------
    Owl theming
    --------------
*/

@each $color, $value in $theme-colors {
    .owl-#{$color} {
        .owl-nav {
            button {
                &.owl-prev,
                &.owl-next {
                    color: color-yiq($value);
                    background: $value;
                }
            }
        }

        .owl-dots {
            .owl-dot {
                &.active {
                    span {
                        background-color: $value;
                    }
                }
            }
        }
    }
}
