﻿/*  
    ------------------
    Transform
    -----------------
*/
.transform-top {
    @include translate-top;
}

.transform-top-50 {
    @include translate-top-50;
}

.transform-right {
    @include translate-right;
}

.transform-right-50 {
    @include translate-right-50;
}

.transform-right-sm {
    @include translate-right-sm;
}

.transform-bottom {
    @include translate-bottom;
}

.transform-bottom-50 {
    @include translate-bottom-50;
}

.transform-bottom-sm {
    @include translate-bottom-sm;
}

.transform-left {
    @include translate-left;
}

.transform-left-50 {
    @include translate-left-50;
}

.transform-left-sm {
    @include translate-left-sm;
}
