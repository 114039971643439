﻿/*  
    -------
    Buttons
    -------
*/

.btn {
    &-rounded {
        @include radiusXl;
    }

    &-icon {
        position: relative;
        padding: 10px;
        width: 3.375rem;
        height: 3.375rem;
        line-height: 2.7rem;
        @include radius(50%);
        @include transition(content 0.4s);

        &-sm {
            width: 2.375rem;
            height: 2.375rem;
            line-height: 1.7rem;
        }

        &-lg {
            width: 4.375rem;
            height: 4.375rem;
            line-height: 3.7rem;
        }

        &-animated {
            &:hover {
                .fa,
                .icon {
                    @include transform(translate(-50%, -50%) rotate(360deg));
                }
            }
        }

        .fa,
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            @include transition(all 0.4s);
        }
    }

    &.active {
        .show {
            display: none;
        }

        .hide {
            display: block;
        }
    }

    .hide {
        display: none;
    }
}
/*  
    --------------
    Buttons social
    --------------
*/
.btn-adn {
    @include btn-social(#d87a68, #d87a68);
}

.btn-bitbucket {
    @include btn-social(#205081, #205081);
}

.btn-dropbox {
    @include btn-social(#1087dd, #1087dd);
}

.btn-facebook {
    @include btn-social(#3b5998, #3b5998);
}

.btn-flickr {
    @include btn-social(#ff0084, #ff0084);
}

.btn-foursquare {
    @include btn-social(#f94877, #f94877);
}

.btn-github {
    @include btn-social(#444444, #444444);
}

.btn-google {
    @include btn-social(#dd4b39, #dd4b39);
}

.btn-instagram {
    @include btn-social(#3f729b, #3f729b);
}

.btn-linkedin {
    @include btn-social(#007bb6, #007bb6);
}

.btn-microsoft {
    @include btn-social(#2672ec, #2672ec);
}

.btn-odnoklassniki {
    @include btn-social(#f4731c, #f4731c);
}

.btn-openid {
    @include btn-social(#f7931e, #f7931e);
}

.btn-pinterest {
    @include btn-social(#cb2027, #cb2027);
}

.btn-reddit {
    @include btn-social(#eff7ff, #000);
}

.btn-soundcloud {
    @include btn-social(#ff5500, #ff5500);
}

.btn-tumblr {
    @include btn-social(#2c4762, #2c4762);
}

.btn-twitter {
    @include btn-social(#55acee, #55acee);
}

.btn-vimeo {
    @include btn-social(#1ab7ea, #1ab7ea);
}

.btn-vk {
    @include btn-social(#587ea3, #587ea3);
}

.btn-yahoo {
    @include btn-social(#720e9e, #720e9e);
}
/*  
    -----------------
    Button slide
    ------------------
*/

[class*="btn-slide"] {
    position: relative;
    border-color: $black;
    border-radius: 0;
    z-index: 1;

    &:hover {
        background-color: transparent;

        &:before {
            width: 100%;
            height: 100%;
            right: 0;
        }
    }

    &:before {
        content: "";
        background-color: $black;
        display: block;
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        @include duration-2;
    }
}

@each $color, $value in $theme-colors {
    .btn-slide-#{$color} {
        border-color: $value;
        color: $value;

        &:hover {
            color: color-yiq($value);
        }

        &:before {
            background-color: $value;
        }
    }
}

/*  
    -----------
    Button blink
    -----------
*/
[class*="btn-blink"] {
    position: relative;
    border-color: $black;
    border-radius: 0;

    &:hover {
        &:before {
            top: -8px;
            left: -8px;
            bottom: -8px;
            right: -8px;
            opacity: 1;
        }
    }

    &:before {
        content: "";
        border: 4px solid $black;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        @include duration-2;
    }
}

@each $color, $value in $theme-colors {
    .btn-blink-#{$color} {
        border-color: $value;
        color: color-yiq($value);
        background-color: $value;

        &:hover {
            background-color: darken($value, 5%);
            color: color-yiq($value);
        }

        &:before {
            border-color: darken($value, 5%);
        }
    }
}

.btn-color {
    width: 1.375rem;
    height: 1.375rem;

    &.active,
    &.focus {
        border-color: $dark;
    }

    @each $color, $value in $color-set {
        &-#{$color} {
            background-color: $value;
        }
    }
}

.bg-primary-gradient {
    background: rgb(0, 0, 0); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(97, 66, 163, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 1) 0%,
        rgba(97, 66, 163, 1) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(97, 66, 163, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#6142a3',GradientType=0 ); /* IE6-9 */
}
