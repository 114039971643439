﻿/*  
    ------------------
    Rellax
    -----------------
*/
.rellax-wrapper {
    overflow: hidden;
    position: relative;

    .flex-content {
        height: 300px;

        @include media-breakpoint-up(xl) {
            & {
                height: 600px;
            }
        }
    }

    &:before {
        position: absolute;
        width: 100%;
        height: 15%;
        left: 0;
        top: 0;
        background: -moz-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to bottom, rgba(248, 248, 248, 1) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
        z-index: 9090;
    }
}

.rellax {
    &-move {
        @include transition(all 0.8s);
    }

    .item {
        border-radius: 10px;
        overflow: hidden;
        opacity: 0.9;
        @include transition(all 0.5s);
    }

    .t-1 {
        padding-top: 20px;

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
        }
    }

    .t-2 {
        padding-top: 50px;

        @include media-breakpoint-up(lg) {
            padding-top: 200px;
        }
    }

    .t-3 {
        padding-top: 100px;

        @include media-breakpoint-up(lg) {
            padding-top: 400px;
        }
    }
}

.rellax-cover {
    @include absolute-cover;

    &-bottom {
        top: 70%;

        @include media-breakpoint-up(lg) {
            & {
                top:30%;
            }
        }
    }

    &-center {
        top: 50%;
        height: auto;
        @include translate-top-50;
    }
}
