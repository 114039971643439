/*  
    ------------------
    Gutters
    -----------------
*/
.gutters {
    &-mobile {
        @include media-breakpoint-down(md) {
            margin-right: -5px;
            margin-left: -5px;

            > .col,
            > [class*="col-"] {
                padding-right: 5px;
                padding-left: 5px;
            }
        }
    }
}
