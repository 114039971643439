/*  
    ----------------------------------
    Page loader
    ----------------------------------
*/
.loader {
    //display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 999999;
    background-color: white;

    .spinner-border {
        position: fixed;
        left: 50%;
        top: 50%;
        @include transition(all .3s);
        margin-left: -16px;
        margin-top: -16px;
    }

    &.loaded {
        width: 0;
        left: 0;
        @include transition(all 1s cubic-bezier(.8,.2,.2,.9));

        .spinner-border {
            opacity: 0;
        }

        &:before {
            opacity: 0;
        }
    }
}
