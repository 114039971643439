.brick {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    &-next {
        &:before {
                padding-top: 125%;       
        }
    }

    &-content {
        @include absolute-full;
    }

    &-text {
        position: absolute!important;
        left: 0;
        bottom: 0;
    }
}
