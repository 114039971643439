/*
    HTML Template v1.0.0 (http://elathemes.com/)
    Copyright 2020 Extended Bootstrap Template
    Bootstrap 4.5 
    Copyright 2020 ElaThemes

    [Table of contents]

    1.  Bootstrap Components
        └── Root
        └── Reboot
        └── Type
        └── Images
        └── Code
        └── Grid
        └── Tables
        └── Forms
        └── Buttons
        └── Transitions
        └── Dropdown
        └── Button Group
        └── Input Group
        └── Custom Forms
        └── Nav
        └── Navbar
        └── Card
        └── Breadcrumb
        └── Pagination
        └── Badge
        └── Jumbotron
        └── Alert
        └── Progress
        └── Media
        └── List Group
        └── Close
        └── Toasts
        └── Modal
        └── Tooltip
        └── Popover
        └── Carousel
        └── Spinners
        └── Utilities
        └── Print

    2. Reveal Components

        2.1 Defaults
            └── Dark-Mode
            └── Defaults
            └── Header
            └── Navbar
            └── Sections
            └── Sidebar
            └── Typography

        2.2 Components
            └── Cards
            └── Buttons
            └── Breadcrumb
            └── Brick
            └── Boxes
            └── Carsouel
            └── Date
            └── Figures
            └── List-Group
            └── Forms
            └── Hero
            └── Nav
            └── Sider
            └── Steps
            └── Tables

        2.3 Utilities
            └── Bg Layer
            └── Blockquotes
            └── Borders
            └── Cover
            └── Colors
            └── Divider
            └── Dropdown
            └── Fonts
            └── Gutters
            └── Headings
            └── Hover
            └── Hr
            └── Icons
            └── Links
            └── Opacity
            └── Position
            └── Pagination
            └── Prelabel
            └── Reveal Animation
            └── Scrollbar
            └── Transform

        2.4 Widgets
            └── Rellax
            └── Stretcher
            └── Tabzy
*/
